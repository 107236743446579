import { useEffect, useState } from "react"

import { HEALTHCHECK_API_URL } from "../constants/urls"
import { useCustomStorage } from "../hooks/useCustomStorage"
import { CircularProgress } from "@mui/material"
import "../style.css"
import { toast } from "react-toastify"

function ActiveCheck() {
  const [inputValue, setInputValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [displayMsg, setDisplayMsg] = useState("")


  const handleInputChange = (e) => {
    if (e.target.value.length > 50) return
    setInputValue(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!inputValue) {
      setIsLoading(false)
      return
    }
    setDisplayMsg("Fetching authorization status...")
    setIsLoading(true)
    fetch(`${HEALTHCHECK_API_URL}?product=Lyris`, {
      method: "GET",
      headers: {
        "x-api-key": inputValue
      }
    }).then((response) => {
      if(response.status === 200){
        setDisplayMsg("This is an active user!")
        setIsLoading(false)
        toast.success("This is an active user!")
      }
      else if (response.status === 403){
        setDisplayMsg("This user is not active. If you have recently created it, try again after some time")
        setIsLoading(false)
        toast.error("User is not active")
      }
      else 
        throw new Error("Something went wrong!")
      })
      .catch((error) => {
        console.error(error)
        setDisplayMsg("This user is not active. If you have recently created it, try again after some time")
        setIsLoading(false)
        toast.error("User is not active")
      })
    setInputValue("")
  }

  return (
    <main>
      {
        isLoading ? (
          <div
            style={{height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
          >
            <CircularProgress />
            <div style={{marginTop: "20px"}}>
              Fetching Authorization Status...
            </div>
          </div>
        ) : (
          <div>
            <div>
              <form 
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter Authorization token here..."
                  style={{
                    padding: "0.5rem",
                    outline: "none",
                    border: "1px solid #ccc",
                    borderRadius: "0.25rem",
                    marginRight: "0.5rem",
                    minWidth: "80%",
                    fontSize: "1rem"
                  }}
                />
                <button
                  style={{
                    padding: "0.75rem",
                    backgroundColor: "#4CAF50"
                  }}
                  type="submit">
                  Submit
                </button>
              </form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p>{displayMsg}</p>
              </div>
            </div>
          </div>
        )
      }
    </main>
  )
}
export default ActiveCheck