import React, { useState, useEffect } from 'react';
import { TENANT_ID_API_URL } from '../constants/urls';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';

import "../style.css";
function Header(){
    return (
        <header className='lyris-header'>
            <div>Lyris Admin Portal</div>
        </header>
    )
}
export default Header;