const dev = false

if (dev) {
  var BASE_URL = "http://127.0.0.1:8001"
} else {
  var BASE_URL = "https://esk67e9hyg.execute-api.us-east-1.amazonaws.com/api"
}

export const FEEDBACK_API_URL = `${BASE_URL}/feedback/feedback`
export const HEALTHCHECK_API_URL = `${BASE_URL}/healthcheck`
export const SCHEMA_CONFIG_API_URL = `${BASE_URL}/configs`
export const TENANT_ID_API_URL = `${BASE_URL}/tenants`
export const USER_API_URL = `${BASE_URL}/users`
export const USER_PROFILE_API_URL = `${BASE_URL}/users/profile`


export const WS_URL = dev
  ? "ws://127.0.0.1:8000"
  : "wss://3p7p4qe0hf.execute-api.us-east-1.amazonaws.com/api"
  