import logo from './logo.svg';
import './App.css';
import AuthTokenInput from './components/Auth';
import ActiveCheck from './components/ActiveCheck';
import { useEffect, useState } from "react"
import Header from './components/Header';
import AdminPanel from './components/AdminPanel';
import { ToastContainer, toast } from 'react-toastify';
import { USER_PROFILE_API_URL } from './constants/urls';
import 'react-toastify/dist/ReactToastify.min.css'
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useCustomStorage } from './hooks/useCustomStorage';
import { CircularProgress } from '@mui/material';

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [authToken, setAuthToken] = useCustomStorage("lyris_auth_token")

  useEffect(() => {
    if (window.location.pathname === "/auth" || window.location.pathname === "/active_check") return
    if (!authToken){
      window.location.href = "/auth"
      return
    }
    setIsLoading(true)
    fetch(`${USER_PROFILE_API_URL}?product=Lyris`, {
      method: "GET",
      headers: {
        "x-api-key": authToken
      }
    })
      .then((response) => {
        if (response) {
          if (response.status !== 200){
            window.location.href = "/auth"
            setIsAuthorized(false)
          }
          return response.json()
        }
      })
      .then((response)=>{
        if(response.isAdmin){
          setIsAuthorized(true)
        }
        else{
          window.location.href = "/auth"
          setIsAuthorized(false)
        }
      })
      .catch((error) => {
        window.location.href = "/auth"
        console.error(error)
        setIsAuthorized(false)
      }).finally(() => {
        setIsLoading(false)
      })
  }, [])
  return (
    <div>
      {
        isLoading ? (
          <div
              style={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
            >
              <CircularProgress />
              <div style={{marginTop: "20px"}}>
                Fetching Authorization Status...
              </div>
            </div>
        ) : (
          <Router>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
              <Header />
              <ToastContainer />
              <Routes>
                <Route path="/"
                  element={isAuthorized ? <AdminPanel />: <></>}
                />
                <Route path="/auth"
                  element={<AuthTokenInput isAuthorized={isAuthorized} setIsAuthorized={setIsAuthorized} />}
                />
                <Route path="/active_check"
                  element={<ActiveCheck />}
                />
              </Routes>
            </div>
          </Router>
        )
      }
    </div>
  );
}

export default App;
