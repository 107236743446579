import React, { useEffect, useState } from 'react';
import "../style.css";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UserTab from "./UserTab"
import TenantTab from "./TenantTab"

function AdminPanel(){
    const [value, setValue] = useState('users');
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
      <div style={{width: "85vw", display: "flex", justifyContent: "center"}}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList onChange={handleTabChange} aria-label="lab API tabs example">
					<Tab label="Users" value="users" />
					<Tab label="Tenants" value="tenants" />
				</TabList>
				</Box>
				<TabPanel value="users">
					<UserTab />
				</TabPanel>
				<TabPanel value="tenants">
					<TenantTab />
				</TabPanel>
			</TabContext>
		</Box>
      </div>
    )
}
export default AdminPanel;